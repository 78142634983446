import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Icon,
  Card,
  Box,
  styled,
  ThemeProvider,
  Button,
} from "@mui/material";
import { createTheme, useTheme } from "@mui/material/styles";
import ColorBlock from "app/views/OtherMaster/ColorBlock";
//import * as XLSX from 'xlsx';
//import * as XLSX from 'xlsx-style';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const CardHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "16px 24px",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: theme.palette.success.main,
}));

const Title = styled("span")(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: theme.palette.primary.contrastText,
}));

const ResponsiveTableContainer = styled(Box)(({ theme, maxHeight }) => ({
  overflowX: "auto",
  maxHeight: maxHeight, // Set the maximum height for scrolling
  "&::-webkit-scrollbar": {
    width: "8px", // Customize the width of the scrollbar
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.success.main, // Change the color of the scrollbar thumb
    borderRadius: "4px", // Add border radius to the thumb
  },
}));

const StickyTableHeader = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  "& .MuiTableCell-head": {
    color: theme.palette.success.contrastText,
    fontWeight: "bold",
    fontSize: "1rem",
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.success.light,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  "&.MuiTableCell-head": {
    borderBottom: `1px solid ${theme.palette.success.main}`,
  },
  "&:last-child": {
    textAlign: "right",
    paddingRight: "15px",
  },
}));

const Small = styled("small")(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: "#fff",
  padding: "2px 8px",
  borderRadius: "4px",
  overflow: "hidden",
  background: bgcolor,
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
}));

const renderCellContent = (row, column, theme) => {

  switch (column.type) {
    case "status":
      return (
        <Small
          status={row[column.field]}
          bgcolor={
            row[column.field] === "A"
              ? theme.palette.success.main
              : row[column.field] === "P"
                ? theme.palette.secondary.main
                : theme.palette.error.main
          }
        >
          {row[column.field] === "A"
            ? "Active"
            : row[column.field] === "P"
              ? "Pending" 
              : row[column.field] === "R"
                ? "Rejected"
              : "Inactive"}
        </Small>
      );

    case "warranty":
      return (

        row[column.field] === true
          ? "Yes"
          : "No"
      );
    case "attchfile":

      const attachmentFileName = row[column.field];
      const absoluteURL = `https://octillionattachments.blob.core.windows.net/ticket-attachments/`;
      const fileNameWithoutPrefix = attachmentFileName.replace(absoluteURL, '');
      const finalURL = `${absoluteURL}${fileNameWithoutPrefix}`;
      //const absoluteURL = `https://octillionattachments.blob.core.windows.net/ticket-attachments/${row[column.field]}`;
      return (
        <a href={finalURL} target="_blank" rel="noopener noreferrer">
          {fileNameWithoutPrefix}
        </a>
      );
    case "colourValue":
    case "backColorValue":
    case "fontColorValue":
      return (
        <div style={{ width: "100px", height: "20px", border: "1px solid black", backgroundColor: row[column.field] }} />
      );
    default:
      return row[column.field];
  }
};

const OctillionTable = ({ title, columns, data, actions, maxHeight,allowDownload }) => {
  const theme = useTheme();
  
const downloadExcel = () => {
  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Data');

  // Define header row and apply styles (bold and colored background)
  const headerRow = columns.map((column) => column.headerName);
  const row = worksheet.addRow(headerRow);

  // Apply header styles
  row.eachCell((cell) => {
    cell.font = { 
      bold: true,
      color: { argb: 'FFFFFFFF' } // White font color
    };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF4CAF50' } // Light green background
    };
    cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Center alignment
  });

  // Set header row height
  row.height = 30; // Set height if needed

  // Add data rows
  data.forEach((rowData) => {
    const rowValues = columns.map((column) => rowData[column.field]);
    worksheet.addRow(rowValues);
  });

  // Set column widths
  columns.forEach((column, index) => {
    worksheet.getColumn(index + 1).width = 25; // Minimum width of 20 characters
  });

  // Generate the Excel file and trigger download
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob,  title+ '.xlsx');
  });
};
  return (
    <ThemeProvider theme={theme}>
      <Card elevation={3}>
        {title && (
          <CardHeader>
            <Title>{title}</Title>
            {allowDownload && 
            <Button variant="contained" size="small" onClick={downloadExcel} style={{ float: 'right' }}>
                  Download Data
                </Button>}
          </CardHeader>
        )}

        <ResponsiveTableContainer maxHeight={maxHeight}>
          <Table>
            <StickyTableHeader>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell key={column.field}>
                    {column.headerName}
                  </StyledTableCell>
                ))}
                {actions && <StyledTableCell>Actions</StyledTableCell>}
              </TableRow>
            </StickyTableHeader>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <StyledTableCell key={column.field}>
                      {renderCellContent(row, column, theme)}
                    </StyledTableCell>
                  ))}
                  {actions && (
                    <StyledTableCell>
                    {
                    actions.map((action, actionIndex) => (
                      // Check if row.status is not equal to "A" and action.icon is not "done"
                      
                      (((row.recordStatus == "P" || row.recordStatus == "R") && action.icon == "done") || (action.icon !== "done" && row.recordStatus != "R") || action.icon=="edit" ) && (
                        <IconButton
                          key={actionIndex}
                          onClick={() => action.onClick(row)}
                        >                          
                          <Icon color={action.color}>{action.icon}</Icon>
                        </IconButton>
                      )
                    ))}
                  </StyledTableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ResponsiveTableContainer>
      </Card>
    </ThemeProvider>
  );
};

export default OctillionTable;
